import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const FullSizeImage = ({ childImageSharp }) => {
  const imageStyle = {
    width: `calc(${childImageSharp.sizes.aspectRatio} * 100vh - 2rem)`,
  }
  return (
    <Img
      loading="eager"
      className="full-size-image"
      style={imageStyle}
      sizes={childImageSharp.sizes}
    />
  )
}

FullSizeImage.propTypes = {
  childImageSharp: PropTypes.object,
}

export default FullSizeImage
