import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FullSizeImage from "../components/FullSizeImage"

const DayTemplate = ({ data, pageContext }) => {
  const images = data.day.nodes
  const next = data.next.nodes[0]
  const previous = data.previous.nodes[0]

  return (
    <Layout
      header={`Every day by ${pageContext.folder[0].toUpperCase()}${pageContext.folder.slice(
        1
      )}`}
      smallHeader={true}
      headerLink={`/${pageContext.folder}`}
    >
      <SEO title={pageContext.date} />
      <section className="section day-section is-centered has-text-centered">
        <div className="full-size-images-wrapper">
          {images.map(({ childImageSharp }, index) => (
            <FullSizeImage childImageSharp={childImageSharp} key={index} />
          ))}
        </div>
      </section>
      <section className="section day-info-section is-centered has-text-centered">
        <h1 className="title has-text-weight-bold has-text-grey is-size-4-mobile has-text-centered">
          {pageContext.date}
        </h1>

        <ul className="day-dots day-dots--day">
          {previous && (
            <li className="day-dot day-dot--left">
              <Link to={`/${pageContext.folder}/${pageContext.previous}`}>
                <Img fixed={previous.childImageSharp.fixed} />
              </Link>
            </li>
          )}
          {next && (
            <li className="day-dot day-dot--right">
              <Link to={`/${pageContext.folder}/${pageContext.next}`}>
                <Img fixed={next.childImageSharp.fixed} />
              </Link>
            </li>
          )}
        </ul>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query($date: String, $previous: String, $next: String, $keyRegex: String) {
    day: allS3ImageAsset(
      filter: {
        Key: { regex: $keyRegex }
        EXIF: { DateCreatedISO: { eq: $date } }
      }
    ) {
      nodes {
        childImageSharp {
          sizes: fluid(maxWidth: 1024) {
            aspectRatio
            base64
            src
            srcSet
            sizes
          }
        }
      }
    }
    previous: allS3ImageAsset(
      filter: {
        Key: { regex: $keyRegex }
        EXIF: { DateCreatedISO: { eq: $previous } }
      }
      limit: 1
    ) {
      nodes {
        childImageSharp {
          fixed(fit: CONTAIN, width: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    next: allS3ImageAsset(
      filter: {
        Key: { regex: $keyRegex }
        EXIF: { DateCreatedISO: { eq: $next } }
      }
      limit: 1
    ) {
      nodes {
        childImageSharp {
          fixed(fit: CONTAIN, width: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default DayTemplate
